import React, { useContext, useEffect, useMemo, useState } from 'react'
import * as classes from './Partnership.module.scss'
import Container from '../../ui/Container/Container'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from '../../../utils/deleteHtmlTag'
import splitRow from '../../../utils/splitRow'
import { startAnimationScroll } from '../../../utils/animations'
import { FormContext } from '../../../context'
import splitTitleV2 from '../../../utils/splitTitleV2'

function Partnership({ data, lang }) {
    const { animationStart } = useContext(FormContext)
    const sectionTitle = useMemo(() => deleteHtmlTag(data.titleSmall.value, 'p'), [])

    const titleOne = useMemo(() => deleteHtmlTag(data.titleOne.value, 'p'), [])
    const textOne = useMemo(() => deleteHtmlTag(data.textOne.value, 'p'), [])
    const emailOne = useMemo(() => deleteHtmlTag(data.emailOne.value, 'p'), [])

    const titleTwo = useMemo(() => deleteHtmlTag(data.titleTwo.value, 'p'), [])
    const textTwo = useMemo(() => deleteHtmlTag(data.textTwo.value, 'p'), [])
    const emailTwo = useMemo(() => deleteHtmlTag(data.emailTwo.value, 'p'), [])

    const [title, setTitle] = useState({
        top: titleOne,
        bottom: titleTwo
    })

    const classAnimationTitle = 'anime-title-partnership'
    const classAnimationText = 'anime-text-partnership'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
            splitTitleV2(classAnimationTitle)
        }

        const observer = startAnimationScroll([
            { type: 'title', className: classAnimationTitle },
            { type: 'text', className: classAnimationText }
        ])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    return (
        <>
            {sectionTitle || title.top || textOne || emailOne ? (
                <section className={classes.Top}>
                    <Container>
                        {sectionTitle ? <SectionTitle title={sectionTitle} lang={lang} /> : null}

                        {title.top ? (
                            <div className={classes.RowTop}>
                                <h2 className={`${classes.Title} ${classAnimationTitle}`} dangerouslySetInnerHTML={{ __html: title.top }} />
                            </div>
                        ) : null}

                        {textOne || emailOne ? (
                            <div className={classes.RowBottom}>
                                <div
                                    className={`${classes.Content} ${classAnimationText}`}
                                    data-lang={lang}
                                >
                                    {textOne ? <p dangerouslySetInnerHTML={{ __html: textOne }} /> : null}

                                    {emailOne ? (
                                        <a
                                            className={classes.Email}
                                            href={`mailto:${emailOne}`}
                                            dangerouslySetInnerHTML={{ __html: emailOne }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </Container>
                </section>
            ) : null}

            {title.bottom || textTwo || emailTwo ? (
                <section className={classes.Bottom}>
                    <Container>
                        {title.bottom ? (
                            <div className={classes.RowTop}>
                                <h2
                                    className={`${classes.Title} ${classAnimationTitle}`}
                                    dangerouslySetInnerHTML={{ __html: title.bottom }}
                                />
                            </div>
                        ) : null}

                        {textTwo || emailTwo ? (
                            <div className={classes.RowBottom}>
                                <div
                                    className={`${classes.Content} ${classAnimationText}`}
                                    data-lang={lang}
                                >
                                    {textTwo ? <p dangerouslySetInnerHTML={{ __html: textTwo }} /> : null}

                                    {emailTwo ? (
                                        <a
                                            className={classes.Email}
                                            href={`mailto:${emailTwo}`}
                                            dangerouslySetInnerHTML={{ __html: emailTwo }}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </Container>
                </section>
            ) : null}
        </>
    )
}

export default Partnership
