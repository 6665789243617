// extracted by mini-css-extract-plugin
export var Top = "Partnership-module--Top--kDn7N";
export var RowTop = "Partnership-module--RowTop--PNEKr";
export var Inner = "Partnership-module--Inner--ENgsz";
export var Title = "Partnership-module--Title--qR6m0";
export var RowBottom = "Partnership-module--RowBottom--WdPIj";
export var Content = "Partnership-module--Content--6-WVH";
export var Email = "Partnership-module--Email--9olJ0";
export var Bottom = "Partnership-module--Bottom--DbqKw";
export var arrowLeft = "Partnership-module--arrowLeft--YGtRX";
export var arrowRight = "Partnership-module--arrowRight--8GvbD";